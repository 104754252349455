import $ from 'jquery';
import _ from 'underscore';
import fetch from 'isomorphic-fetch';
import { buildBulkUpdateRequest, convertBulkUpdateResponse } from 'common/dot-net-data-helpers';
import localforage from 'common/localforage';
import embeddedData from 'hudl-base/src/embedded-data';
import { Service } from 'hudl-base';
import { checkStatus } from 'common/fetch-helpers';
import * as DialogTypes from 'common/dialog-types';
import * as EditTypes from 'common/edit-types';
import UsageLogger from 'common/usage-logger';

const usageLogger = new UsageLogger();

export const TOAST__SHOW = 'TOAST__SHOW';
export const showToast = (toast) => ({
  type: TOAST__SHOW,
  payload: toast,
});

export const TOAST__DISMISS = 'TOAST__DISMISS';
export const dismissToast = () => ({ type: TOAST__DISMISS });

export const TOGGLE_FORCE_PUBLIC_VIEW = 'TOGGLE_FORCE_PUBLIC_VIEW';
export const toggleForcePublicView = (forcePublicView) => ({
  type: TOGGLE_FORCE_PUBLIC_VIEW,
  payload: {
    forcePublicView,
  },
});

export const CHANGE_SECTION = 'CHANGE_SECTION';
export function changeSection(name, wasActive) {
  return {
    type: CHANGE_SECTION,
    payload: {
      name,
      wasActive,
    },
  };
}

export const CHANGE_SCHEDULE_SEASON = 'CHANGE_SCHEDULE_SEASON';

function changeSeasonSuccess(seasonId) {
  return {
    type: CHANGE_SCHEDULE_SEASON,
    payload: {
      seasonId,
    },
  };
}

export const LOAD_SEASON_DETAILS = 'LOAD_SEASON_DETAILS';

function loadSeasonDetails(seasonId, data) {
  return {
    type: 'LOAD_SEASON_DETAILS',
    payload: {
      seasonId,
      data,
    },
  };
}

export function changeScheduleSeason(seasonId) {
  return (dispatch, getState) => {
    dispatch(changeSeasonSuccess(seasonId));
    const state = getState();

    if (state.team.allSeasons && state.team.allSeasons.length) {
      const selectedSeason = _.findWhere(state.team.allSeasons, { id: seasonId });
      if (selectedSeason.events.length) {
        // Fetch details from the server
        const getSeasonDetails = embeddedData.services.getSeasonDetails + '?seasonId=' + encodeURIComponent(seasonId);
        return fetch(getSeasonDetails, {
          credentials: 'same-origin',
        })
          .then(checkStatus)
          .then(response => response.json())
          .then(json => dispatch(loadSeasonDetails(seasonId, json)));
      }
    }
  };
}

export const CHANGE_SCHEDULE_VIEW = 'CHANGE_SCHEDULE_VIEW';

export function changeScheduleView(view) {
  return {
    type: CHANGE_SCHEDULE_VIEW,
    payload: {
      view,
    },
  };
}

export const CLOSE_DIALOG = 'CLOSE_DIALOG';

export function closeDialog(dialogType) {
  return {
    type: CLOSE_DIALOG,
    payload: {
      dialogType,
    },
  };
}

export const OPEN_DIALOG = 'OPEN_DIALOG';

export function openDialog(dialogType, dialogData) {
  return {
    type: OPEN_DIALOG,
    payload: {
      dialogType,
      data: dialogData,
    },
  };
}

export const REQUEST_PROFILE_FOLLOWERS = 'REQUEST_PROFILE_FOLLOWERS';

export function requestProfileFollowers(beforeDateTime = null) {
  return {
    type: REQUEST_PROFILE_FOLLOWERS,
    payload: {
      beforeDateTime,
    },
  };
}

export const RECEIVE_PROFILE_FOLLOWERS = 'RECEIVE_PROFILE_FOLLOWERS';

export function receiveProfileFollowers(json, beforeDateTime = null) {
  return {
    type: RECEIVE_PROFILE_FOLLOWERS,
    payload: {
      json,
      beforeDateTime,
    },
  };
}

export const FAIL_PROFILE_FOLLOWERS_REQUEST = 'FAIL_PROFILE_FOLLOWERS_REQUEST';

export function failProfileFollowersRequest(error) {
  return {
    type: FAIL_PROFILE_FOLLOWERS_REQUEST,
    error: true,
    payload: error,
  };
}

export function fetchProfileFollowers(beforeDateTime = null) {
  return dispatch => {
    // Update store to show request is taking place
    dispatch(requestProfileFollowers(beforeDateTime));

    const beforeDateTimeString = beforeDateTime ? beforeDateTime.toISOString() : null;

    // Fetch followers
    let getProfileFollowers = embeddedData.services.getProfileFollowers;
    getProfileFollowers += '?beforeDateTime=' + encodeURIComponent(beforeDateTimeString);
    return fetch(getProfileFollowers, {
      credentials: 'same-origin',
    })
      .then(checkStatus)
      .then(response => response.json())
      .then(json => dispatch(receiveProfileFollowers(json, beforeDateTime)))
      .catch(error => dispatch(failProfileFollowersRequest(error)));
  };
}

export const REQUEST_PROFILE_FOLLOWING = 'REQUEST_PROFILE_FOLLOWING';

export function requestProfileFollowing(beforeDateTime = null) {
  return {
    type: REQUEST_PROFILE_FOLLOWING,
    payload: {
      beforeDateTime,
    },
  };
}

export const RECEIVE_PROFILE_FOLLOWING = 'RECEIVE_PROFILE_FOLLOWING';

export function receiveProfileFollowing(json, beforeDateTime = null) {
  return {
    type: RECEIVE_PROFILE_FOLLOWING,
    payload: {
      json,
      beforeDateTime,
    },
  };
}

export const FAIL_PROFILE_FOLLOWING_REQUEST = 'FAIL_PROFILE_FOLLOWING_REQUEST';

export function failProfileFollowingRequest(error) {
  return {
    type: FAIL_PROFILE_FOLLOWING_REQUEST,
    error: true,
    payload: error,
  };
}

export function fetchProfileFollowing(beforeDateTime = null) {
  return dispatch => {
    // Update store to show request is taking place
    dispatch(requestProfileFollowing(beforeDateTime));

    const beforeDateTimeString = beforeDateTime ? beforeDateTime.toISOString() : null;

    // Fetch friends
    let getProfileFollowing = embeddedData.services.getProfileFollowing;
    getProfileFollowing += '?beforeDateTime=' + encodeURIComponent(beforeDateTimeString);
    return fetch(getProfileFollowing, {
      credentials: 'same-origin',
    })
      .then(checkStatus)
      .then(response => response.json())
      .then(json => dispatch(receiveProfileFollowing(json, beforeDateTime)))
      .catch(error => dispatch(failProfileFollowingRequest(error)));
  };
}

export const REQUEST_FOLLOW_FEED_USER = 'REQUEST_FOLLOW_FEED_USER';

export function requestFollowFeedUser(feedUserTypeId, feedUserRelatedId, isCurrentProfile, userIsOnOwnProfile,
  followerInfo) {
  return {
    type: REQUEST_FOLLOW_FEED_USER,
    payload: {
      feedUserTypeId,
      feedUserRelatedId,
      isCurrentProfile,
      userIsOnOwnProfile,
      followerInfo,
    },
  };
}

export const RECEIVE_FEED_USER_FOLLOWED = 'RECEIVE_FEED_USER_FOLLOWED';

export function receiveFeedUserFollowed(feedUserTypeId, feedUserRelatedId, isCurrentProfile, userIsOnOwnProfile,
  followerInfo) {
  return {
    type: RECEIVE_FEED_USER_FOLLOWED,
    payload: {
      feedUserTypeId,
      feedUserRelatedId,
      isCurrentProfile,
      userIsOnOwnProfile,
      followerInfo,
    },
  };
}

export const FAIL_FEED_USER_FOLLOW_REQUEST = 'FAIL_FEED_USER_FOLLOW_REQUEST';

export function failFeedUserFollowRequest(error, feedUserTypeId, feedUserRelatedId, isCurrentProfile,
  userIsOnOwnProfile, followerInfo) {
  const errorToSend = new Error(error);
  errorToSend.feedUserTypeId = feedUserTypeId;
  errorToSend.feedUserRelatedId = feedUserRelatedId;
  errorToSend.isCurrentProfile = isCurrentProfile;
  errorToSend.userIsOnOwnProfile = userIsOnOwnProfile;
  errorToSend.followerInfo = followerInfo;
  return {
    type: FAIL_FEED_USER_FOLLOW_REQUEST,
    error: true,
    payload: errorToSend,
  };
}

function isUserOnOwnProfile(getState) {
  const currentState = getState();
  const user = currentState.user;
  const currentUser = currentState.currentUser;
  let userIsOnOwnProfile = false;
  if (user && currentUser && user.userId && user.userId === currentUser.userId) {
    userIsOnOwnProfile = true;
  }
  return userIsOnOwnProfile;
}

function getFollowerInfo(feedUserTypeId, feedUserRelatedId, getState) {
  const currentState = getState();
  const followers = currentState && currentState.followers && currentState.followers.users;
  if (followers) {
    return _.find(followers,
      follower => follower.userId === feedUserRelatedId && follower.feedUserType === feedUserTypeId);
  }
  return undefined;
}

export function followFeedUser(feedUserTypeId, feedUserRelatedId, isCurrentProfile, logData) {
  return (dispatch, getState) => {
    const userIsOnOwnProfile = isUserOnOwnProfile(getState);
    const followerInfo = getFollowerInfo(feedUserTypeId, feedUserRelatedId, getState);
    const args = [feedUserTypeId, feedUserRelatedId, isCurrentProfile, userIsOnOwnProfile, followerInfo];
    // Update store to show request is taking place
    dispatch(requestFollowFeedUser(...args));

    // Send request
    const followFeedUserEndpoint = embeddedData.services.followFeedUser;
    return fetch(followFeedUserEndpoint, {
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'post',
      body: JSON.stringify({
        friendId: {
          type: feedUserTypeId,
          relatedId: feedUserRelatedId,
        },
        logData: {
          origin: 'TeamProfile',
        },
        ...logData,
      }),
    })
      .then(checkStatus)
      .then(() => dispatch(receiveFeedUserFollowed(...args)))
      .catch(error => dispatch(failFeedUserFollowRequest(error, ...args)));
  };
}

export const REQUEST_UNFOLLOW_FEED_USER = 'REQUEST_UNFOLLOW_FEED_USER';

export function requestUnfollowFeedUser(feedUserTypeId, feedUserRelatedId, isCurrentProfile, userIsOnOwnProfile,
  followerInfo) {
  return {
    type: REQUEST_UNFOLLOW_FEED_USER,
    payload: {
      feedUserTypeId,
      feedUserRelatedId,
      isCurrentProfile,
      userIsOnOwnProfile,
      followerInfo,
    },
  };
}

export const RECEIVE_FEED_USER_UNFOLLOWED = 'RECEIVE_FEED_USER_UNFOLLOWED';

export function receiveFeedUserUnfollowed(feedUserTypeId, feedUserRelatedId, isCurrentProfile, userIsOnOwnProfile,
  followerInfo) {
  return {
    type: RECEIVE_FEED_USER_UNFOLLOWED,
    payload: {
      feedUserTypeId,
      feedUserRelatedId,
      isCurrentProfile,
      userIsOnOwnProfile,
      followerInfo,
    },
  };
}

export const FAIL_FEED_USER_UNFOLLOW_REQUEST = 'FAIL_FEED_USER_UNFOLLOW_REQUEST';

export function failFeedUserUnfollowRequest(error, feedUserTypeId, feedUserRelatedId, isCurrentProfile,
  userIsOnOwnProfile, followerInfo) {
  const errorToSend = new Error(error);
  errorToSend.feedUserTypeId = feedUserTypeId;
  errorToSend.feedUserRelatedId = feedUserRelatedId;
  errorToSend.isCurrentProfile = isCurrentProfile;
  errorToSend.userIsOnOwnProfile = userIsOnOwnProfile;
  errorToSend.followerInfo = followerInfo;
  return {
    type: FAIL_FEED_USER_UNFOLLOW_REQUEST,
    error: true,
    payload: errorToSend,
  };
}

export function unfollowFeedUser(feedUserTypeId, feedUserRelatedId, isCurrentProfile, logData) {
  return (dispatch, getState) => {
    const userIsOnOwnProfile = isUserOnOwnProfile(getState);
    const followerInfo = getFollowerInfo(feedUserTypeId, feedUserRelatedId, getState);
    const args = [feedUserTypeId, feedUserRelatedId, isCurrentProfile, userIsOnOwnProfile, followerInfo];
    // Update store to show request is taking place
    dispatch(requestUnfollowFeedUser(...args));

    // Send request
    const unfollowFeedUserEndpoint = embeddedData.services.unfollowFeedUser;
    return fetch(unfollowFeedUserEndpoint, {
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'post',
      body: JSON.stringify({
        friendId: {
          type: feedUserTypeId,
          relatedId: feedUserRelatedId,
        },
        logData: {
          origin: 'TeamProfile',
        },
        ...logData,
      }),
    })
      .then(checkStatus)
      .then(() => dispatch(receiveFeedUserUnfollowed(...args)))
      .catch(error => {
        dispatch(failFeedUserUnfollowRequest(error, ...args));
      });
  };
}

export const CHANGE_HIGHLIGHT_SORT = 'CHANGE_HIGHLIGHT_SORT';

export function changeHighlightSort(sort) {
  return {
    type: CHANGE_HIGHLIGHT_SORT,
    payload: {
      sort,
    },
  };
}

export const CHANGE_HIGHLIGHT_VIEW = 'CHANGE_HIGHLIGHT_VIEW';

export function changeHighlightView(view) {
  return {
    type: CHANGE_HIGHLIGHT_VIEW,
    payload: {
      view,
    },
  };
}

export const REMOVE_HIGHLIGHT = 'REMOVE_HIGHLIGHT';

export function removeHighlight(legacyReelId) {
  return {
    type: REMOVE_HIGHLIGHT,
    payload: {
      legacyReelId,
    },
  };
}

export const REQUEST_TEAM_NAME_UPDATE = 'REQUEST_TEAM_NAME_UPDATE';

export function requestTeamNameUpdate(teamId, teamName, isMens) {
  return {
    type: REQUEST_TEAM_NAME_UPDATE,
    payload: {
      teamId,
      teamName,
      isMens,
    },
  };
}

export const RECEIVE_TEAM_NAME_UPDATED = 'RECEIVE_TEAM_NAME_UPDATED';

export function receiveTeamNameUpdated(teamId, teamName, isMens) {
  return {
    type: RECEIVE_TEAM_NAME_UPDATED,
    payload: {
      teamId,
      teamName,
      isMens,
    },
  };
}

export const TIMEOUT_TEAM_NAME_UPDATED = 'TIMEOUT_TEAM_NAME_UPDATED';

export function timeoutTeamNameUpdated(teamId, teamName, isMens) {
  return {
    type: TIMEOUT_TEAM_NAME_UPDATED,
    payload: {
      teamId,
      teamName,
      isMens,
    },
  };
}

export const FAIL_TEAM_NAME_UPDATE = 'FAIL_TEAM_NAME_UPDATE';

export function failTeamNameUpdate(error, teamId, teamName, isMens) {
  const errorToSend = new Error(error);
  errorToSend.teamId = teamId;
  errorToSend.teamName = teamName;
  errorToSend.isMens = isMens;
  errorToSend.type = EditTypes.TEAM_NAME;
  return {
    type: FAIL_TEAM_NAME_UPDATE,
    error: true,
    payload: errorToSend,
  };
}

export function updateTeamName(teamId, teamName, isMens) {
  return dispatch => {
    // Update store to show request is taking place
    dispatch(requestTeamNameUpdate(teamId, teamName, isMens));

    // Send request
    const updateTeamNameEndpoint = embeddedData.services.updateTeamName;
    return fetch(updateTeamNameEndpoint, {
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
      },
      method: 'post',
      body: JSON.stringify({
        isMens,
        teamId,
        teamName,
      }),
    })
      .then(checkStatus)
      .then(() => {
        dispatch(receiveTeamNameUpdated(teamId, teamName, isMens));
        setTimeout(() => dispatch(timeoutTeamNameUpdated(teamId, teamName, isMens)), 5000);
      })
      .catch(error => {
        dispatch(failTeamNameUpdate(error, teamId, teamName, isMens));
        setTimeout(() => dispatch(timeoutTeamNameUpdated(teamId, teamName, isMens)), 5000);
      });
  };
}

export const UPDATE_TEAM_NAME_ERROR = 'UPDATE_TEAM_NAME_ERROR';

export function updateTeamNameError(isError, errorMessage) {
  return {
    type: UPDATE_TEAM_NAME_ERROR,
    payload: {
      isError,
      errorMessage,
    },
  };
}

export const REQUEST_TEAM_COLOR_UPDATE = 'REQUEST_TEAM_COLOR_UPDATE';

export function requestTeamColorUpdate(teamId, teamColor) {
  return {
    type: REQUEST_TEAM_COLOR_UPDATE,
    payload: {
      teamId,
      teamColor,
    },
  };
}

export const RECEIVE_TEAM_COLOR_UPDATED = 'RECEIVE_TEAM_COLOR_UPDATED';

export function receiveTeamColorUpdated(teamId, teamColor) {
  return {
    type: RECEIVE_TEAM_COLOR_UPDATED,
    payload: {
      teamId,
      teamColor,
    },
  };
}

export const TIMEOUT_TEAM_COLOR_UPDATED = 'TIMEOUT_TEAM_COLOR_UPDATED';

export function timeoutTeamColorUpdated(teamId, teamColor) {
  return {
    type: TIMEOUT_TEAM_COLOR_UPDATED,
    payload: {
      teamId,
      teamColor,
    },
  };
}

export const FAIL_TEAM_COLOR_UPDATE = 'FAIL_TEAM_COLOR_UPDATE';

export function failTeamColorUpdate(error, teamId, teamColor) {
  const errorToSend = new Error(error);
  errorToSend.teamId = teamId;
  errorToSend.teamColor = teamColor;
  errorToSend.type = EditTypes.TEAM_COLOR;
  return {
    type: FAIL_TEAM_COLOR_UPDATE,
    error: true,
    payload: errorToSend,
  };
}

export function updateTeamColor(teamId, teamColor) {
  return dispatch => {
    // Update store to show request is taking place
    dispatch(requestTeamColorUpdate(teamId, teamColor));

    // Send request
    const updateTeamColorEndpoint = embeddedData.services.updateTeamColor;
    return fetch(updateTeamColorEndpoint, {
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
      },
      method: 'put',
      body: JSON.stringify({
        color: teamColor,
      }),
    })
      .then(checkStatus)
      .then(() => {
        dispatch(receiveTeamColorUpdated(teamId, teamColor));
        setTimeout(() => dispatch(timeoutTeamColorUpdated(teamId, teamColor)), 5000);
      })
      .catch(error => {
        dispatch(failTeamColorUpdate(error, teamId, teamColor));
        setTimeout(() => dispatch(timeoutTeamColorUpdated(teamId, teamColor)), 5000);
      });
  };
}

export const TEMP_PROFILE_IMAGE_UPLOADED = 'TEMP_PROFILE_IMAGE_UPLOADED';

export function tempProfileImageUploaded(imageSrc, imageId) {
  return {
    type: TEMP_PROFILE_IMAGE_UPLOADED,
    payload: {
      imageSrc,
      imageId,
    },
  };
}

export const BEFORE_TEAM_PROFILE_SAVE = 'BEFORE_TEAM_PROFILE_SAVE';

export function beforeTeamProfileSave() {
  return {
    type: BEFORE_TEAM_PROFILE_SAVE,
    payload: {},
  };
}

export const BEFORE_USER_PROFILE_SAVE = 'BEFORE_USER_PROFILE_SAVE';

export function beforeUserProfileSave() {
  return {
    type: BEFORE_USER_PROFILE_SAVE,
    payload: {},
  };
}

export const REQUEST_TEAM_IMAGE_UPDATE = 'REQUEST_TEAM_IMAGE_UPDATE';

export function requestTeamImageUpdate(args) {
  return {
    type: REQUEST_TEAM_IMAGE_UPDATE,
    payload: args,
  };
}

export const REQUEST_USER_IMAGE_UPDATE = 'REQUEST_USER_IMAGE_UPDATE';

export function requestUserImageUpdate(args) {
  return {
    type: REQUEST_USER_IMAGE_UPDATE,
    payload: args,
  };
}

export const RECEIVE_TEAM_IMAGE_UPDATED = 'RECEIVE_TEAM_IMAGE_UPDATED';

export function receiveTeamImageUpdated(args, newImageSource) {
  const newArgs = $.extend(true, {}, args);
  newArgs.newImageSource = newImageSource;
  return {
    type: RECEIVE_TEAM_IMAGE_UPDATED,
    payload: newArgs,
  };
}

export const RECEIVE_USER_IMAGE_UPDATED = 'RECEIVE_USER_IMAGE_UPDATED';

export function receiveUserImageUpdated(args, newImageSource) {
  const newArgs = $.extend(true, {}, args);
  newArgs.newImageSource = newImageSource;
  return {
    type: RECEIVE_USER_IMAGE_UPDATED,
    payload: newArgs,
  };
}

export const FAIL_TEAM_IMAGE_UPDATE = 'FAIL_TEAM_IMAGE_UPDATE';

export function failTeamImageUpdate(error, args) {
  const errorToSend = new Error(error);
  errorToSend.args = args;
  errorToSend.type = errorToSend.args.imageType === 'TEAM_PHOTO' ? EditTypes.BANNER_IMAGE : EditTypes.LOGO_IMAGE;
  return {
    type: FAIL_TEAM_IMAGE_UPDATE,
    error: true,
    payload: errorToSend,
  };
}

export const FAIL_USER_IMAGE_UPDATE = 'FAIL_USER_IMAGE_UPDATE';

export function failUserImageUpdate(error, args) {
  const errorToSend = new Error(error);
  errorToSend.args = args;
  errorToSend.type = errorToSend.args.imageType === 'PROFILE' ? EditTypes.LOGO_IMAGE : EditTypes.BANNER_IMAGE;
  return {
    type: FAIL_USER_IMAGE_UPDATE,
    error: true,
    payload: errorToSend,
  };
}

export function updateTeamImage(args) {
  return dispatch => {
    // Update store to show request is taking place
    dispatch(requestTeamImageUpdate(args));

    // Send request
    const updateTeamImageEndpoint = embeddedData.services.updateTeamImage;
    return fetch(updateTeamImageEndpoint, {
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
      },
      method: 'post',
      body: JSON.stringify({
        imageId: args.imageId,
        url: args.url,
        width: args.width,
        height: args.height,
        x: args.x,
        y: args.y,
        destinationWidth: args.imageType === 'TEAM_LOGO' ? 500 : args.width,
        destinationHeight: args.imageType === 'TEAM_LOGO' ? 500 : args.height,
        imageType: args.imageType,
        imageParentId: args.imageParentId,
        teamId: args.teamId,
      }),
    })
      .then(checkStatus)
      .then((response) => {
        response.json().then((result) => {
          dispatch(receiveTeamImageUpdated(args, result.Uri));
          let dialog;
          switch (args.imageType) {
          case 'TEAM_LOGO':
            dialog = DialogTypes.EDIT_PROFILE_IMAGE_AREA_SELECT_DIALOG;
            usageLogger.logCompleteEditPicture('Profile', 'TeamPage', args.currentUserId, args.teamId, true);
            break;
          case 'TEAM_PHOTO':
            dialog = DialogTypes.EDIT_BANNER_IMAGE_AREA_SELECT_DIALOG;
            usageLogger.logCompleteEditPicture('Banner', 'TeamPage', args.currentUserId, args.teamId, true);
            break;
          default:
            dialog = DialogTypes.NONE;
            break;
          }
          dispatch(closeDialog(dialog));
        });
      })
      .catch(error => {
        dispatch(failTeamImageUpdate(error, args));
      });
  };
}

export const BEGIN_EDIT_PICTURE_UPDATE = 'BEGIN_EDIT_PICTURE_UPDATE';

export function beginEditPictureUpdate(imageType, profileType, authUser, userId, isTeam) {
  switch (imageType) {
  case 'Profile':
    usageLogger.logBeginEditPicture('ProfilePicture', profileType, authUser, userId, isTeam);
    break;
  case 'Banner':
    usageLogger.logBeginEditPicture('BannerPicture', profileType, authUser, userId, isTeam);
    break;
  default:
    break;
  }
  return {
    type: BEGIN_EDIT_PICTURE_UPDATE,
    payload: {
      imageType,
      profileType,
      userId,
      authUser,
    },
  };
}
export function updateUserImage(args) {
  return dispatch => {
    // Update store to show request is taking place
    dispatch(requestUserImageUpdate(args));

    // Send request
    const updateUserImageEndpoint = embeddedData.services.updateUserImage;
    return fetch(updateUserImageEndpoint, {
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
      },
      method: 'post',
      body: JSON.stringify({
        imageId: args.imageId,
        url: args.url,
        width: args.width,
        height: args.height,
        x: args.x,
        y: args.y,
        destinationWidth: args.imageType === 'PROFILE' ? 500 : args.width,
        destinationHeight: args.imageType === 'PROFILE' ? 500 : args.height,
        imageType: args.imageType,
        imageParentId: args.imageParentId,
        userId: args.userId,
      }),
    })
      .then(checkStatus)
      .then((response) => {
        response.json().then((result) => {
          dispatch(receiveUserImageUpdated(args, result.Uri));
          let dialog;
          switch (args.imageType) {
          case 'PROFILE':
            usageLogger.logCompleteEditPicture('Profile', 'AthletePage',
              args.currentUserId, args.userId, false);
            dialog = DialogTypes.EDIT_PROFILE_IMAGE_AREA_SELECT_DIALOG;
            break;
          case 'USER_BANNER':
            usageLogger.logCompleteEditPicture('Banner', 'AthletePage',
              args.currentUserId, args.userId, false);
            dialog = DialogTypes.EDIT_BANNER_IMAGE_AREA_SELECT_DIALOG;
            break;
          default:
            dialog = DialogTypes.NONE;
            break;
          }
          dispatch(closeDialog(dialog));
        });
      })
      .catch(error => {
        dispatch(failUserImageUpdate(error, args));
      });
  };
}
export const BEFORE_TEAM_BANNER_SAVE = 'BEFORE_TEAM_BANNER_SAVE';

export function beforeTeamBannerSave() {
  return {
    type: BEFORE_TEAM_BANNER_SAVE,
    payload: {},
  };
}

export const TEMP_BANNER_IMAGE_UPLOADED = 'TEMP_BANNER_IMAGE_UPLOADED';

export function tempBannerImageUploaded(imageSrc, imageId) {
  return {
    type: TEMP_BANNER_IMAGE_UPLOADED,
    payload: {
      imageSrc,
      imageId,
    },
  };
}


export const REQUEST_TEAM_TAGLINE_UPDATE = 'REQUEST_TEAM_TAGLINE_UPDATE';

export function requestTeamTaglineUpdate(teamId, teamTagline) {
  return {
    type: REQUEST_TEAM_TAGLINE_UPDATE,
    payload: {
      teamId,
      teamTagline,
    },
  };
}

export const RECEIVE_TEAM_TAGLINE_UPDATED = 'RECEIVE_TEAM_TAGLINE_UPDATED';

export function receiveTeamTaglineUpdated(teamId, teamTagline) {
  return {
    type: RECEIVE_TEAM_TAGLINE_UPDATED,
    payload: {
      teamId,
      teamTagline,
    },
  };
}

export const TIMEOUT_TEAM_TAGLINE_UPDATED = 'TIMEOUT_TEAM_TAGLINE_UPDATED';

export function timeoutTeamTaglineUpdated(teamId, teamTagline) {
  return {
    type: TIMEOUT_TEAM_TAGLINE_UPDATED,
    payload: {
      teamId,
      teamTagline,
    },
  };
}

export const FAIL_TEAM_TAGLINE_UPDATE = 'FAIL_TEAM_TAGLINE_UPDATE';

export function failTeamTaglineUpdate(error, teamId, teamTagline) {
  const errorToSend = new Error(error);
  errorToSend.teamId = teamId;
  errorToSend.teamTagline = teamTagline;
  errorToSend.type = EditTypes.TAGLINE;
  return {
    type: FAIL_TEAM_TAGLINE_UPDATE,
    error: true,
    payload: errorToSend,
  };
}

export function updateTeamTagline(teamId, teamTagline) {
  return dispatch => {
    // Update store to show request is taking place
    dispatch(requestTeamTaglineUpdate(teamId, teamTagline));

    // Send request
    const updateTeamTaglineEndpoint = embeddedData.services.updateTeamTagline;
    return fetch(updateTeamTaglineEndpoint, {
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
      },
      method: 'put',
      body: JSON.stringify({
        tagline: teamTagline,
      }),
    })
      .then(checkStatus)
      .then(() => {
        dispatch(receiveTeamTaglineUpdated(teamId, teamTagline));
        setTimeout(() => dispatch(timeoutTeamTaglineUpdated(teamId, teamTagline)), 5000);
      })
      .catch(error => {
        dispatch(failTeamTaglineUpdate(error, teamId, teamTagline));
        setTimeout(() => dispatch(timeoutTeamTaglineUpdated(teamId, teamTagline)), 5000);
      });
  };
}

export const UPDATE_TEAM_TAGLINE_ERROR = 'UPDATE_TEAM_TAGLINE_ERROR';

export function updateTeamTaglineError(isError, errorMessage) {
  return {
    type: UPDATE_TEAM_TAGLINE_ERROR,
    payload: {
      isError,
      errorMessage,
    },
  };
}

export const CHANGE_TEAM_SEASON = 'CHANGE_TEAM_SEASON';

export function changeTeamSeason(season) {
  return {
    type: CHANGE_TEAM_SEASON,
    payload: {
      season,
    },
  };
}

export const CHANGE_TEAM_SORT = 'CHANGE_TEAM_SORT';

export function changeTeamSort(sort) {
  return {
    type: CHANGE_TEAM_SORT,
    payload: {
      sort,
    },
  };
}

export const REQUEST_TEAM_ATHLETES = 'REQUEST_TEAM_ATHLETES';

export function requestTeamAthletes(seasonId) {
  return {
    type: REQUEST_TEAM_ATHLETES,
    payload: {
      seasonId,
    },
  };
}

export const RECEIVE_TEAM_ATHLETES = 'RECEIVE_TEAM_ATHLETES';

export function receiveTeamAthletes(json, seasonId) {
  return {
    type: RECEIVE_TEAM_ATHLETES,
    payload: {
      json,
      seasonId,
    },
  };
}

export const FAIL_TEAM_ATHLETES_REQUEST = 'FAIL_TEAM_ATHLETES_REQUEST';

export function failTeamAthletesRequest(error, seasonId) {
  const errorToSend = new Error(error);
  errorToSend.seasonId = seasonId;
  return {
    type: FAIL_TEAM_ATHLETES_REQUEST,
    error: true,
    payload: errorToSend,
  };
}

export function fetchTeamAthletes(seasonId) {
  return (dispatch, getState) => {
    // Update store to show request is taking place
    dispatch(requestTeamAthletes(seasonId));
    const state = getState();

    let isCurrent = false;
    if (state.team.allSeasons && state.team.allSeasons.length) {
      const season = _.findWhere(state.team.allSeasons, { id: seasonId });
      isCurrent = !!(season && season.isCurrent);
    }

    // Fetch athletes
    let getTeamAthletes = embeddedData.services.getTeamAthletes;
    getTeamAthletes += '?seasonId=' + encodeURIComponent(seasonId);
    getTeamAthletes += '&isCurrent=' + encodeURIComponent(isCurrent);
    return fetch(getTeamAthletes, {
      credentials: 'same-origin',
    })
      .then(checkStatus)
      .then(response => response.json())
      .then(json => dispatch(receiveTeamAthletes(json, seasonId)))
      .catch(error => dispatch(failTeamAthletesRequest(error, seasonId)));
  };
}

export const DISMISS_FEEDBACK_CTA = 'DISMISS_FEEDBACK_CTA';

export function dismissFeedbackClick(userId, teamId) {
  let key = ``;
  if (teamId) {
    key = `${userId}-team-profile-cta-feedback-dismissed`;
  } else {
    key = `${userId}-athlete-profile-cta-feedback-dismissed`;
  }
  try {
    localforage.setItem(key, true);
  } finally {
    return {
      type: DISMISS_FEEDBACK_CTA,
      payload: {
      },
    };
  }
}

export const DISMISS_FEEDBACK = 'DISMISS_FEEDBACK';

export function dismissFeedback(userId, teamId) {
  let key = ``;
  if (teamId) {
    key = `${userId}-team-profile-feedback-dismissed`;
  } else {
    key = `${userId}-athlete-profile-feedback-dismissed`;
  }
  try {
    localforage.setItem(key, true);
  } finally {
    return {
      type: DISMISS_FEEDBACK,
      payload: {
      },
    };
  }
}


export const FEEDBACK_SELECTED = 'FEEDBACK_SELECTED';

export function feedbackSelected(feedbackType) {
  return {
    type: FEEDBACK_SELECTED,
    payload: {
      feedbackType,
    },
  };
}

export const PAGE_VIEW = 'PAGE_VIEW';

export function pageView(userId, teamId) {
  let key = '';
  if (teamId) {
    key = `${userId}-team-profile-page-views`;
  } else {
    key = `${userId}-athlete-profile-page-views`;
  }
  try {
    localforage.getItem(key).then(pageViews => {
      let newPageViews = 1;
      if (pageViews === parseInt(pageViews, 10)) {
        newPageViews = pageViews + 1;
      }
      localforage.setItem(key, newPageViews);
    });
  } finally {
    return {
      type: PAGE_VIEW,
      payload: {
      },
    };
  }
}

export const SELECT_ORDER = 'SELECT_ORDER';

export function selectOrder(gameId) {
  return {
    type: SELECT_ORDER,
    payload: {
      gameId,
    },
  };
}

export const REQUEST_ORDER_URL = 'REQUEST_ORDER_URL';

export function requestOrderUrl(teamId, gameId, password, returnUrl) {
  return {
    type: REQUEST_ORDER_URL,
    payload: {
      teamId,
      gameId,
      password,
      returnUrl,
    },
  };
}

export const START_BULK_SAVE = 'START_BULK_SAVE';

export function startBulkSave(edits) {
  return {
    type: START_BULK_SAVE,
    payload: {
      edits,
    },
  };
}

export const ALL_SAVES_COMPLETE_OR_ERROR = 'ALL_SAVES_COMPLETE_OR_ERROR';

export function allSavesCompleteOrError(edits) {
  return {
    type: ALL_SAVES_COMPLETE_OR_ERROR,
    payload: {
      edits,
    },
  };
}

export const RESET_BULK_EDIT_SUCCESS_OR_ERROR = 'RESET_BULK_EDIT_SUCCESS_OR_ERROR';

export function resetBulkEditSuccessOrError() {
  return {
    type: RESET_BULK_EDIT_SUCCESS_OR_ERROR,
    payload: {
    },
  };
}

export const CLEAR_STAGED_UPDATES = 'CLEAR_STAGED_UPDATES';

export function clearStagedUpdates() {
  return {
    type: CLEAR_STAGED_UPDATES,
    payload: {
    },
  };
}

export const STAGE_UPDATE = 'STAGE_UPDATE';
export const UNSTAGE_UPDATE = 'UNSTAGE_UPDATE';

export function stageTeamColorUpdate(teamId, teamColor, savedColor) {
  const payload = {
    type: EditTypes.TEAM_COLOR,
    teamId,
    teamColor,
    savedColor,
  };

  return {
    type: teamColor === savedColor ? UNSTAGE_UPDATE : STAGE_UPDATE,
    payload,
  };
}

export function stageTeamNameUpdate(teamId, teamName, isMens, savedName) {
  const payload = {
    type: EditTypes.TEAM_NAME,
    teamId,
    teamName,
    isMens,
    savedName,
  };

  return {
    type: teamName === savedName ? UNSTAGE_UPDATE : STAGE_UPDATE,
    payload,
  };
}

export function stageTeamTaglineUpdate(teamId, teamTagline, savedTagline) {
  const payload = {
    type: EditTypes.TAGLINE,
    teamId,
    teamTagline,
    savedTagline,
  };

  return {
    type: teamTagline === savedTagline ? UNSTAGE_UPDATE : STAGE_UPDATE,
    payload,
  };
}

export function stageTeamImageUpdate(args) {
  return {
    type: STAGE_UPDATE,
    payload: {
      type: args.imageType === 'TEAM_PHOTO' ? EditTypes.BANNER_IMAGE : EditTypes.LOGO_IMAGE,
      args,
    },
  };
}

export function stageLeagueNameUpdate(teamId, leagueName, savedName) {
  const payload = {
    type: EditTypes.LEAGUE_NAME,
    teamId,
    leagueName,
    savedName,
  };

  return {
    type: leagueName === savedName ? UNSTAGE_UPDATE : STAGE_UPDATE,
    payload,
  };
}

export const REQUEST_LEAGUE_NAME_UPDATE = 'REQUEST_LEAGUE_NAME_UPDATE';

export function requestLeagueNameUpdate(teamId, leagueName) {
  return {
    type: REQUEST_LEAGUE_NAME_UPDATE,
    payload: {
      teamId,
      leagueName,
    },
  };
}

export const RECEIVE_LEAGUE_NAME_UPDATED = 'RECEIVE_LEAGUE_NAME_UPDATED';

export function receiveLeagueNameUpdated(teamId, leagueName) {
  return {
    type: RECEIVE_LEAGUE_NAME_UPDATED,
    payload: {
      teamId,
      leagueName,
    },
  };
}

export const FAIL_LEAGUE_NAME_UPDATE = 'FAIL_LEAGUE_NAME_UPDATE';

export function failLeagueNameUpdate(error, teamId, leagueName) {
  const errorToSend = new Error(error);
  errorToSend.teamId = teamId;
  errorToSend.leagueName = leagueName;
  errorToSend.type = EditTypes.LEAGUE_NAME;
  return {
    type: FAIL_LEAGUE_NAME_UPDATE,
    error: true,
    payload: errorToSend,
  };
}

export const TIMEOUT_LEAGUE_NAME_UPDATED = 'TIMEOUT_LEAGUE_NAME_UPDATED';

export function timeoutLeagueNameUpdated(teamId, leagueName) {
  return {
    type: TIMEOUT_LEAGUE_NAME_UPDATED,
    payload: {
      teamId,
      leagueName,
    },
  };
}

export const UPDATE_LEAGUE_NAME_ERROR = 'UPDATE_LEAGUE_NAME_ERROR';

export function updateLeagueNameError(isError, errorMessage) {
  return {
    type: UPDATE_LEAGUE_NAME_ERROR,
    payload: {
      isError,
      errorMessage,
    },
  };
}

export function stageTeamClassUpdate(teamId, teamClass, savedName) {
  const payload = {
    type: EditTypes.TEAM_CLASS,
    teamId,
    teamClass,
    savedName,
  };

  return {
    type: teamClass === savedName ? UNSTAGE_UPDATE : STAGE_UPDATE,
    payload,
  };
}

export const REQUEST_TEAM_CLASS_UPDATE = 'REQUEST_TEAM_CLASS_UPDATE';

export function requestTeamClassUpdate(teamId, teamClass) {
  return {
    type: REQUEST_TEAM_CLASS_UPDATE,
    payload: {
      teamId,
      teamClass,
    },
  };
}

export const RECEIVE_TEAM_CLASS_UPDATED = 'RECEIVE_TEAM_CLASS_UPDATED';

export function receiveTeamClassUpdated(teamId, teamClass) {
  return {
    type: RECEIVE_TEAM_CLASS_UPDATED,
    payload: {
      teamId,
      teamClass,
    },
  };
}

export const FAIL_TEAM_CLASS_UPDATE = 'FAIL_TEAM_CLASS_UPDATE';

export function failTeamClassUpdate(error, teamId, teamClass) {
  const errorToSend = new Error(error);
  errorToSend.teamId = teamId;
  errorToSend.teamClass = teamClass;
  errorToSend.type = EditTypes.TEAM_CLASS;
  return {
    type: FAIL_TEAM_CLASS_UPDATE,
    error: true,
    payload: errorToSend,
  };
}

export const TIMEOUT_TEAM_CLASS_UPDATED = 'TIMEOUT_TEAM_CLASS_UPDATED';

export function timeoutTeamClassUpdated(teamId, teamClass) {
  return {
    type: TIMEOUT_TEAM_CLASS_UPDATED,
    payload: {
      teamId,
      teamClass,
    },
  };
}

export function savePendingEdits(teamId, edits = [], redirectToHomeOnSuccess) {
  return dispatch => {
    // Initiate beginning of save (probably just marking save button as in-progress)
    dispatch(startBulkSave(edits));

    for (const edit of edits) {
      switch (edit.type) {
      case EditTypes.BANNER_IMAGE:
        dispatch(requestTeamImageUpdate(edit.args));
        break;
      case EditTypes.LEAGUE_NAME:
        dispatch(requestLeagueNameUpdate(edit.teamId, edit.leagueName));
        break;
      case EditTypes.LOGO_IMAGE:
        dispatch(requestTeamImageUpdate(edit.args));
        break;
      case EditTypes.TAGLINE:
        dispatch(requestTeamTaglineUpdate(edit.teamId, edit.teamTagline));
        break;
      case EditTypes.TEAM_CLASS:
        dispatch(requestTeamClassUpdate(edit.teamId, edit.teamClass));
        break;
      case EditTypes.TEAM_COLOR:
        dispatch(requestTeamColorUpdate(edit.teamId, edit.teamColor));
        break;
      case EditTypes.TEAM_NAME:
        dispatch(requestTeamNameUpdate(edit.teamId, edit.teamName, edit.isMens));
        break;
      default:
        break;
      }
    }

    function dispatchFailedEdits(error, failedEdits) {
      for (const edit of failedEdits) {
        switch (edit.type) {
        case EditTypes.BANNER_IMAGE:
          dispatch(failTeamImageUpdate(error, edit.args));
          break;
        case EditTypes.LEAGUE_NAME:
          dispatch(failLeagueNameUpdate(error, edit.teamId, edit.leagueName));
          setTimeout(dispatch.bind(this, timeoutLeagueNameUpdated(edit.teamId, edit.leagueName)), 5000);
          break;
        case EditTypes.LOGO_IMAGE:
          dispatch(failTeamImageUpdate(error, edit.args));
          break;
        case EditTypes.TAGLINE:
          dispatch(failTeamTaglineUpdate(error, edit.teamId, edit.teamTagline));
          setTimeout(dispatch.bind(this, timeoutTeamTaglineUpdated(edit.teamId, edit.teamTagline)), 5000);
          break;
        case EditTypes.TEAM_CLASS:
          dispatch(failTeamClassUpdate(error, edit.teamId, edit.teamClass));
          setTimeout(dispatch.bind(this, timeoutTeamClassUpdated(edit.teamId, edit.teamClass)), 5000);
          break;
        case EditTypes.TEAM_COLOR:
          dispatch(failTeamColorUpdate(error, edit.teamId, edit.teamColor));
          setTimeout(dispatch.bind(this, timeoutTeamColorUpdated(edit.teamId, edit.teamColor)), 5000);
          break;
        case EditTypes.TEAM_NAME:
          dispatch(failTeamNameUpdate(error, edit.teamId, edit.teamName, edit.isMens));
          setTimeout(dispatch.bind(this, timeoutTeamNameUpdated(edit.teamId, edit.teamName, edit.isMens)), 5000);
          break;
        default:
          break;
        }
      }
    }

    const requestBody = buildBulkUpdateRequest(edits);
    requestBody.TeamId = teamId;
    const saveUrl = embeddedData.services.save;
    return fetch(saveUrl, {
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'post',
      body: JSON.stringify(requestBody),
    })
      .then(checkStatus)
      .then(response => response.json())
      .then(json => {
        const response = convertBulkUpdateResponse(json);
        for (const success of response.successfulUpdates) {
          const edit = edits.find(e => e.type === success);
          switch (success) {
          case EditTypes.BANNER_IMAGE:
            dispatch(receiveTeamImageUpdated(edit.args, response.bannerImageUri));
            break;
          case EditTypes.LEAGUE_NAME:
            dispatch(receiveLeagueNameUpdated(edit.teamId, edit.leagueName));
            setTimeout(dispatch.bind(this, timeoutLeagueNameUpdated(edit.teamId, edit.leagueName)), 5000);
            break;
          case EditTypes.LOGO_IMAGE:
            dispatch(receiveTeamImageUpdated(edit.args, response.profileImageUri));
            break;
          case EditTypes.TAGLINE:
            dispatch(receiveTeamTaglineUpdated(edit.teamId, edit.teamTagline));
            setTimeout(dispatch.bind(this, timeoutTeamTaglineUpdated(edit.teamId, edit.teamTagline)), 5000);
            break;
          case EditTypes.TEAM_CLASS:
            dispatch(receiveTeamClassUpdated(edit.teamId, edit.teamClass));
            setTimeout(dispatch.bind(this, timeoutTeamClassUpdated(edit.teamId, edit.teamClass)), 5000);
            break;
          case EditTypes.TEAM_COLOR:
            dispatch(receiveTeamColorUpdated(edit.teamId, edit.teamColor));
            setTimeout(dispatch.bind(this, timeoutTeamColorUpdated(edit.teamId, edit.teamColor)), 5000);
            break;
          case EditTypes.TEAM_NAME:
            dispatch(receiveTeamNameUpdated(edit.teamId, edit.teamName, edit.isMens));
            setTimeout(dispatch.bind(this, timeoutTeamNameUpdated(edit.teamId, edit.teamName, edit.isMens)), 5000);
            break;
          default:
            break;
          }
        }

        const failedEdits = edits.filter(edit => {
          return response.failedUpdates.some(failedUpdate => failedUpdate === edit.type);
        });
        const error = new Error('Failure saving some or all values.');
        dispatchFailedEdits(error, failedEdits);

        if (!failedEdits.length && redirectToHomeOnSuccess) {
          window.location = '/home';
        }
      })
      .catch(error => dispatchFailedEdits(error, edits))
      .then(() => {
        dispatch(allSavesCompleteOrError(edits));
        setTimeout(() => dispatch(resetBulkEditSuccessOrError()), 3000);
      });
  };
}

export const REQUEST_SCHOOL_OPTIONS = 'REQUEST_SCHOOL_OPTIONS';

export function requestSchoolOptions(query) {
  return {
    type: REQUEST_SCHOOL_OPTIONS,
    payload: {
      query,
    },
  };
}

export const RECEIVE_SCHOOL_OPTIONS = 'RECEIVE_SCHOOL_OPTIONS';

export function receiveSchoolOptions(json, query) {
  return {
    type: RECEIVE_SCHOOL_OPTIONS,
    payload: {
      json,
      query,
    },
  };
}

export const FAIL_SCHOOL_OPTIONS_REQUEST = 'FAIL_SCHOOL_OPTIONS_REQUEST';

export function failSchoolOptionsRequest(error, query) {
  const errorToSend = new Error(error);
  errorToSend.query = query;
  return {
    type: FAIL_SCHOOL_OPTIONS_REQUEST,
    error: true,
    payload: errorToSend,
  };
}

export function fetchSchoolOptions(query) {
  return dispatch => {
    // Update store to show request is taking place
    dispatch(requestSchoolOptions(query));

    // Fetch school options
    let getSchoolOptions = embeddedData.services.schoolSearch;
    getSchoolOptions += '?query=' + encodeURIComponent(query);
    return fetch(getSchoolOptions, {
      credentials: 'same-origin',
    })
      .then(checkStatus)
      .then(response => response.json())
      .then(json => dispatch(receiveSchoolOptions(json, query)))
      .catch(error => dispatch(failSchoolOptionsRequest(error, query)));
  };
}

export const CHANGE_VIDEOS_SORT = 'CHANGE_VIDEOS_SORT';

export function changeVideosSort(sort) {
  return {
    type: CHANGE_VIDEOS_SORT,
    payload: {
      sort,
    },
  };
}

export const RECEIVE_CONTENT_ITEMS = 'RECEIVE_CONTENT_ITEMS';

export function receiveContentItems(contentItems, isComplete) {
  return {
    type: RECEIVE_CONTENT_ITEMS,
    payload: {
      contentItems,
      isComplete,
    },
  };
}

export function fetchAllContentItems(lastContentId) {
  return dispatch => {
    // Fetch content items
    let getContentItems = embeddedData.services.getContent;
    getContentItems += '?lastContentId=' + encodeURIComponent(lastContentId);
    return fetch(getContentItems, {
      credentials: 'same-origin',
    })
      .then(checkStatus)
      .then(response => response.json())
      .then(json => {
        dispatch(receiveContentItems(json.contentItems, json.isComplete));
        if (json.isComplete === false) {
          let newLastContentId = null;
          if (json.contentItems && json.contentItems.length) {
            newLastContentId = json.contentItems[json.contentItems.length - 1].id;
          }
          dispatch(fetchAllContentItems(newLastContentId));
        }
      })
      .catch(() => dispatch(receiveContentItems([], true)));
  };
}

export const CHANGE_ABOUT_TAB = 'CHANGE_ABOUT_TAB';

export function changeAboutTab(tab) {
  return {
    type: CHANGE_ABOUT_TAB,
    payload: {
      tab,
    },
  };
}

export const EXPAND_PAST_TEAMS = 'EXPAND_PAST_TEAMS';

export function expandPastTeams() {
  return {
    type: EXPAND_PAST_TEAMS,
    payload: {
    },
  };
}

export const REQUEST_FEATURE_HIGHLIGHT = 'REQUEST_FEATURE_HIGHLIGHT';

function requestFeatureHighlight(ownerId, reelId, legacyReelId) {
  return {
    type: REQUEST_FEATURE_HIGHLIGHT,
    payload: {
      ownerId,
      reelId,
      legacyReelId,
    },
  };
}

export const FAIL_FEATURE_HIGHLIGHT = 'FAIL_FEATURE_HIGHLIGHT';

function failFeatureHighlight(ownerId, reelId, legacyReelId) {
  return {
    type: FAIL_FEATURE_HIGHLIGHT,
    payload: {
      ownerId,
      reelId,
      legacyReelId,
    },
  };
}

export const SUCCESS_FEATURE_HIGHLIGHT = 'SUCCESS_FEATURE_HIGHLIGHT';

function successFeatureHighlight(ownerId, reelId, legacyReelId, showUndo) {
  return {
    type: SUCCESS_FEATURE_HIGHLIGHT,
    payload: {
      ownerId,
      reelId,
      legacyReelId,
      showUndo,
    },
  };
}

export function pinHighlight(ownerId, reelId, legacyReelId, oldLegacyReelId) {
  return dispatch => {
    dispatch(requestFeatureHighlight(ownerId, reelId, legacyReelId));
    return new Service('featureHighlight', {
      ownerId,
      reelId: legacyReelId,
    })
      .post({
        oldLegacyReelId,
      })
      .fail(() => dispatch(failFeatureHighlight(ownerId, reelId, legacyReelId)))
      .done(() => dispatch(successFeatureHighlight(ownerId, reelId, legacyReelId, true)));
  };
}

export const REQUEST_UNFEATURE_HIGHLIGHT = 'REQUEST_INFEATURE_HIGHLIGHT';

function requestUnfeatureHighlight(ownerId, reelId, legacyReelId) {
  return {
    type: REQUEST_UNFEATURE_HIGHLIGHT,
    payload: {
      ownerId,
      reelId,
      legacyReelId,
    },
  };
}

export const FAIL_UNFEATURE_HIGHLIGHT = 'FAIL_UNFEATURE_HIGHLIGHT';

function failUnfeatureHighlight(ownerId, reelId, legacyReelId) {
  return {
    type: FAIL_UNFEATURE_HIGHLIGHT,
    payload: {
      ownerId,
      reelId,
      legacyReelId,
    },
  };
}

export const SUCCESS_UNFEATURE_HIGHLIGHT = 'SUCCESS_UNFEATURE_HIGHLIGHT';

function successUnfeatureHighlight(ownerId, reelId, legacyReelId, showUndo) {
  return {
    type: SUCCESS_UNFEATURE_HIGHLIGHT,
    payload: {
      ownerId,
      reelId,
      legacyReelId,
      showUndo,
    },
  };
}

export function unpinHighlight(ownerId, reelId = null, legacyReelId = null, oldLegacyReelId = null, showUndo = true) {
  return dispatch => {
    dispatch(requestUnfeatureHighlight(ownerId, reelId, legacyReelId));
    return new Service('removeFeaturedHighlight', {
      ownerId,
    })
      .delete({
        oldLegacyReelId,
      })
      .fail(() => dispatch(failUnfeatureHighlight(ownerId, reelId, legacyReelId)))
      .done(() => dispatch(successUnfeatureHighlight(ownerId, reelId, legacyReelId, showUndo)));
  };
}

export const DISMISS_NOTIFICATION = 'DISMISS_NOTIFICATION';

export function dismissNotification() {
  return {
    type: DISMISS_NOTIFICATION,
    payload: {
    },
  };
}

export function undoNotificationAction(type) {
  switch (type) {
  case SUCCESS_FEATURE_HIGHLIGHT:
  case SUCCESS_UNFEATURE_HIGHLIGHT:
    return (dispatch, getState) => {
      const state = getState();
      const ownerId = state.user.userId;
      const reelId = state.user.lastFeaturedReelId;
      const currentReelId = state.user.featuredReelId;
      if (reelId) {
        return dispatch(pinHighlight(ownerId, null, reelId, currentReelId))
          .always(() => dispatch(dismissNotification()));
      }
      return dispatch(unpinHighlight(ownerId, null, null, currentReelId, false))
        .always(() => dispatch(dismissNotification()));
    };
  default:
    return dismissNotification();
  }
}

export const SUCCESS_ATHLETE_GAMES = 'SUCCESS_ATHLETE_GAMES';
const successAthleteGames = (result) => ({
  type: SUCCESS_ATHLETE_GAMES,
  payload: result,
});

export const FAIL_ATHLETE_GAMES = 'FAIL_ATHLETE_GAMES';
const failAthleteGames = () => ({ type: FAIL_ATHLETE_GAMES });

export const LOAD_ATHLETE_GAMES = 'LOAD_ATHLETE_GAMES';
const loadAthleteGames = () => ({ type: LOAD_ATHLETE_GAMES });

export const CLEAR_ATHLETE_GAMES = 'CLEAR_ATHLETE_GAMES';
const internalClearAthleteGames = () => ({ type: CLEAR_ATHLETE_GAMES });

export function clearAthleteGames() {
  return dispatch => {
    dispatch(internalClearAthleteGames());
  };
}

export function requestAthleteGames(gamesService) {
  return dispatch => {
    dispatch(loadAthleteGames());
    return gamesService
      .get()
      .then(result => {
        return dispatch(successAthleteGames(result));
      })
      .fail(() => dispatch(failAthleteGames()));
  };
}

export const CHANGE_GAMES_TEAM_VIEW = 'CHANGE_GAMES_TEAM_VIEW';
const internalChangeTeamView = (teamId, seasonId) => ({
  type: CHANGE_GAMES_TEAM_VIEW,
  payload: { teamId, seasonId },
});

export const changeGamesTeamView = (teamId) => (dispatch, getState) => {
  const state = getState();
  const seasonIdForTeam = state.games.teams[teamId].defaultSeasonId;
  dispatch(internalChangeTeamView(teamId, seasonIdForTeam));
};

export const CHANGE_GAMES_SEASON_VIEW = 'CHANGE_GAMES_SEASON_VIEW';
export const changeGamesSeasonView = (seasonId) => ({
  type: CHANGE_GAMES_SEASON_VIEW,
  payload: { seasonId },
});
